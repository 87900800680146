<!-- ======= Services Section ======= -->
<section id="services" class="services section-bg">
    <div class="container">

      <div class="section-title">
        <h2>Чем наши мастер-классы отличаются от остальных</h2>
        <p>Самое основное отличие, что обучение проходит не в классе, а непосредственно в произведственных помещениях, под чутким руководством наших мастеров!</p>
      </div>

      <div class="row gy-4">
        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box iconbox-blue b-09 shadow-lg">
            <div class="icon">
              <!-- Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 100 100" style="enable-background:new 0 0 100 100;" xml:space="preserve">
              <path style="fill:#231F20;" d="M80.599,56.214c-0.06-1.138-0.144-2.328-0.27-3.569c-0.75-7.431-3.041-17.866-9.694-25.375
              c-5.8-6.547-13.909-9.866-24.103-9.866c-14.916,0-26.385,9.451-28.13,12.197C9.78,36.906,4.881,47.424,4.881,58.822l0.047,0.374
              c0.083,0.321,2.123,7.903,10.929,11.882c12.292,5.557,27.918,11.519,44.604,11.518c3.31,0,6.665-0.235,10.04-0.754
              c21.819-3.355,24.617-8.4,24.617-12.421C95.118,68.527,95.115,65.818,80.599,56.214z M68.383,29.252
              c10.383,11.71,9.486,31.693,9.11,36.41l-17.479,4.37c0.192-4.176,0.467-13.987-0.6-22.917c-1.293-10.812-4.65-22.971-18.183-26.286
              c1.691-0.267,3.459-0.424,5.302-0.424C55.826,20.404,63.177,23.381,68.383,29.252z M33.516,22.949
              c14.008,0.043,20.869,7.392,22.919,24.522c0.996,8.33,0.777,17.699,0.597,22.006l-5.436-3.078c0.45-4.675,1.669-23.929-7.559-33.028
              c-5.833-5.751-11.99-7.464-16.839-7.241C28.981,25.022,31.106,23.903,33.516,22.949z M20.767,31.544
              c0.114-0.083,0.223-0.17,0.321-0.255c0.289-0.232,0.575-0.468,0.873-0.692c3.423-2.227,12.009-2.939,19.97,4.91
              c7.932,7.822,7.126,25.188,6.712,30.167c-3.924-0.167-15.5-0.948-24.934-4.462c-6.283-2.34-11.17-6.349-14.531-11.906
              C11.089,42.396,15.074,36.223,20.767,31.544z M70.045,78.876c-19.609,3.016-38.598-4.044-52.952-10.533
              c-6.871-3.105-8.886-8.684-9.211-9.732c0.01-1.771,0.163-3.514,0.424-5.228c4.461,5.977,10.139,9.069,14.355,10.64
              c11.048,4.115,24.432,4.626,26.928,4.687l8.598,4.869l22.064-5.516l0.125-1.024c0.021-0.171,0.346-2.957,0.328-7.14
              c7.163,4.876,11.153,8.461,11.414,9.521C92.118,73.443,84.692,76.624,70.045,78.876z"/>
              </svg>
            </div>
            <h4><a href="">Производственные площадки</a></h4>
            <p>Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box iconbox-orange b-09 shadow-lg">
            <div class="icon">
              <!-- Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 100.353 100.353" style="enable-background:new 0 0 100.353 100.353;" xml:space="preserve">
                <g>
                <path style="fill:#231F20;" d="M49.465,71.651c0-0.886-0.719-1.604-1.604-1.604H14.094c0.834-14.664,13.025-26.338,27.891-26.338
                c5.385,0,10.611,1.535,15.112,4.439c0.748,0.48,1.738,0.266,2.219-0.479c0.479-0.745,0.266-1.738-0.479-2.218
                C53.816,42.212,47.988,40.5,41.984,40.5c-17.174,0-31.146,13.975-31.146,31.151c0,0.886,0.719,1.604,1.604,1.604h35.417
                C48.746,73.256,49.465,72.537,49.465,71.651z"/>
                <path style="fill:#231F20;" d="M41.465,37.931c9.96,0,18.063-8.104,18.063-18.066c0-9.955-8.104-18.054-18.063-18.054
                c-9.953,0-18.051,8.099-18.051,18.054C23.414,29.827,31.512,37.931,41.465,37.931z M41.465,5.02c8.19,0,14.854,6.66,14.854,14.845
                c0,8.192-6.664,14.857-14.854,14.857c-8.184,0-14.842-6.665-14.842-14.857C26.623,11.679,33.282,5.02,41.465,5.02z"/>
                <path style="fill:#231F20;" d="M90.958,89.211L80.619,74.174c1.964-2.612,3.053-5.76,3.075-9.087
                c0.062-8.475-6.781-15.421-15.257-15.483c-8.484-0.059-15.422,6.793-15.483,15.267c-0.022,3.409,1.087,6.559,2.956,9.124
                L45.345,88.887c-0.383,0.54-0.396,1.258-0.031,1.811c0.363,0.552,1.031,0.825,1.675,0.687l8.287-1.771l1.454,7.777
                c0.125,0.668,0.657,1.185,1.329,1.29c0.083,0.013,0.166,0.02,0.249,0.02c0.582,0,1.127-0.318,1.41-0.842l8.486-15.702l8.263,15.821
                c0.279,0.535,0.83,0.862,1.421,0.862c0.075,0,0.15-0.005,0.227-0.016c0.673-0.096,1.213-0.604,1.347-1.271l1.567-7.754l8.249,1.887
                c0.644,0.147,1.313-0.115,1.685-0.663C91.334,90.475,91.332,89.756,90.958,89.211z M68.335,52.813c0.026,0,0.054,0,0.08,0
                c6.705,0.049,12.119,5.545,12.07,12.251v0.001c-0.021,3.245-1.308,6.288-3.622,8.568c-2.293,2.258-5.319,3.499-8.532,3.499
                c-0.03,0-0.061,0-0.092,0c-6.702-0.046-12.119-5.538-12.076-12.239C56.212,58.215,61.665,52.813,68.335,52.813z M59.052,92.343
                l-0.924-4.939c-0.079-0.425-0.327-0.8-0.688-1.041s-0.805-0.324-1.225-0.233l-5.871,1.254l7.771-10.954
                c2.088,1.863,4.683,3.16,7.555,3.663L59.052,92.343z M80.14,86.302c-0.422-0.097-0.864-0.019-1.228,0.216
                c-0.363,0.235-0.617,0.606-0.702,1.03l-0.996,4.927l-6.446-12.342c2.815-0.447,5.432-1.665,7.61-3.554l7.605,11.059L80.14,86.302z"
                />
                </g>
                </svg>
            </div>
            <h4><a href="">Мастера своего дела</a></h4>
            <p>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box iconbox-pink b-09 shadow-lg" style="fill: black; color: black;">
            <div class="icon">
                <!-- Generator: Adobe Illustrator 21.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 100.353 100.352" style="enable-background:new 0 0 100.353 100.352;" xml:space="preserve">
                <g>
                <path d="M49.936,52.438c-12.864,0-23.33,10.466-23.33,23.329v1.456h46.659v-1.456C73.265,62.904,62.799,52.438,49.936,52.438z
                    M29.568,74.312c0.749-10.582,9.598-18.963,20.367-18.963c10.769,0,19.618,8.38,20.367,18.963H29.568z"/>
                <path d="M49.552,50.052c7.521,0,13.639-6.119,13.639-13.639c0-7.52-6.119-13.637-13.639-13.637
                c-7.519,0-13.636,6.118-13.636,13.637C35.917,43.933,42.034,50.052,49.552,50.052z M49.552,25.687
                c5.915,0,10.728,4.812,10.728,10.726c0,5.915-4.813,10.728-10.728,10.728c-5.914,0-10.725-4.813-10.725-10.728
                C38.828,30.499,43.638,25.687,49.552,25.687z"/>
                <path d="M18.744,60.962c2.642,0,5.237,0.705,7.505,2.039l1.476-2.509c-2.715-1.597-5.821-2.441-8.981-2.441
                c-9.769,0-17.716,7.947-17.716,17.717v1.456h21.677v-2.911H4.01C4.743,66.828,11.072,60.962,18.744,60.962z"/>
                <path d="M18.461,55.224c5.797,0,10.513-4.715,10.513-10.511c0-5.797-4.716-10.513-10.513-10.513
                c-5.795,0-10.509,4.716-10.509,10.513C7.951,50.509,12.666,55.224,18.461,55.224z M18.461,37.111c4.192,0,7.602,3.41,7.602,7.602
                c0,4.191-3.41,7.6-7.602,7.6c-4.19,0-7.598-3.409-7.598-7.6C10.862,40.521,14.271,37.111,18.461,37.111z"/>
                <path d="M81.256,58.051c-3.16,0-6.266,0.844-8.981,2.441l1.475,2.509c2.268-1.334,4.863-2.039,7.505-2.039
                c7.673,0,14.001,5.867,14.734,13.35H77.298v2.911h21.673v-1.456C98.972,65.998,91.025,58.051,81.256,58.051z"/>
                <path d="M81.54,55.224c5.797,0,10.513-4.715,10.513-10.511c0-5.797-4.716-10.513-10.513-10.513
                c-5.795,0-10.509,4.716-10.509,10.513C71.031,50.509,75.745,55.224,81.54,55.224z M81.54,37.111c4.192,0,7.602,3.41,7.602,7.602
                c0,4.191-3.41,7.6-7.602,7.6c-4.19,0-7.598-3.409-7.598-7.6C73.942,40.521,77.35,37.111,81.54,37.111z"/>
                </g>
                </svg>

            </div>
            <h4><a href="">Атмосфера сообщества</a></h4>
            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia</p>
          </div>
        </div>

        <!-- <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box iconbox-yellow">
            <div class="icon">
              <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"></path>
              </svg>
              <i class="bx bx-layer"></i>
            </div>
            <h4><a href="">Nemo Enim</a></h4>
            <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box iconbox-red">
            <div class="icon">
              <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"></path>
              </svg>
              <i class="bx bx-slideshow"></i>
            </div>
            <h4><a href="">Dele Cardo</a></h4>
            <p>Quis consequatur saepe eligendi voluptatem consequatur dolor consequuntur</p>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
          <div class="icon-box iconbox-teal">
            <div class="icon">
              <svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
                <path stroke="none" stroke-width="0" fill="#f5f5f5" d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"></path>
              </svg>
              <i class="bx bx-arch"></i>
            </div>
            <h4><a href="">Divera Don</a></h4>
            <p>Modi nostrum vel laborum. Porro fugit error sit minus sapiente sit aspernatur</p>
          </div>
        </div> -->

      </div>

    </div>
  </section><!-- End Services Section -->