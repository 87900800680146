    <!-- ======= Pricing Section ======= -->
    <section id="pricing" class="pricing section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Ближайшые события</h2>
          <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
        </div>

        <div class="row">

          <div class="col-xl-3 col-xl-6 xl-4 mt-xl-0 mx-auto b-09 m-3">
            <div class="box featured">
              <h3 style="background: #e95f12;">04/12/2021 11:00</h3>
              <h2>Печенье</h2>
              <!-- <h4><sup>$</sup>19<span> / month</span></h4> -->
              <carousel>
                <div class="carousel-cell">
                  <img src="assets/img/noroot.png">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/portfolio/portfolio-1.jpg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/portfolio/portfolio-2.jpg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/portfolio/portfolio-3.jpg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/portfolio/portfolio-4.jpg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/portfolio/portfolio-5.jpg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/portfolio/portfolio-6.jpg">
                </div>
              </carousel>
              <div class="btn-wrap">
                <a routerLink="/reservation" class="btn-buy" style="background: #e95f12;">Забронировать</a>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-xl-6 xl-4 mt-xl-0 mx-auto b-09 m-3">
            <div class="box featured">
              <h3 style="background: #e95f12;">??/12/2021</h3>
              <h2>Лепка из марципанов</h2>
              <!-- <h4><sup>$</sup>19<span> / month</span></h4> -->
              <carousel>
                <div class="carousel-cell">
                    <img src="assets/img/new1.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new2.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new3.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new4.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new5.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new6.jpeg">
                </div>
              </carousel>
              <div class="btn-wrap">
                <a routerLink="/reservation" class="btn-buy" style="background: #e95f12;">Забронировать</a>
              </div>
            </div>
          </div>

          <!-- <div class="col-xl-3 col-xl-6 xl-4 mt-xl-0 mx-auto b-09 m-3">
            <div class="box featured">
              <h3 style="background: #e95f12;">??/12/2021</h3>
              <h2>Лепка из марципанов</h2>
              <h4><sup>$</sup>19<span> / month</span></h4>
              <carousel>
                <div class="carousel-cell">
                    <img src="assets/img/new1.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new2.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new3.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new4.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new5.jpeg">
                </div>
                <div class="carousel-cell">
                  <img src="assets/img/new6.jpeg">
                </div>
              </carousel>
              <div class="btn-wrap">
                <a routerLink="/reservation" class="btn-buy" style="background: #e95f12;">Забронировать</a>
              </div>
            </div>
          </div> -->
        </div>
        <nav aria-label="Page navigation example" class="m-5">
          <ul class="pagination justify-content-center pagination-lg">
            <li class="page-item disabled">
              <a class="page-link" href="#" tabindex="-1">Предыдущие</a>
            </li>
            <li class="page-item"><a class="page-link" style="color: #e95f12;" href="#">1</a></li>
            <li class="page-item"><a class="page-link" style="color: #e95f12;" href="#">2</a></li>
            <li class="page-item"><a class="page-link" style="color: #e95f12;" href="#">3</a></li>
            <li class="page-item">
              <a class="page-link" href="#">Следующие</a>
            </li>
          </ul>
        </nav>
      </div>
    </section><!-- End Pricing Section -->