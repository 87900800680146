<section id="hero" class="d-flex align-items-center">

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="container col-xl-10 col-xxl-8 px-4 py-5">
            <div class="row align-items-center g-lg-5 py-5">
              <div class="col-lg-7 text-center text-lg-start">
                <h1 class="display-4 fw-bold lh-1 mb-3">Бронирование</h1>
                <!-- <p class="col-lg-10 fs-4">Below is an example form built entirely with Bootstrap’s form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> -->
              </div>
              <div class="col-md-10 mx-auto col-lg-5">
                <form class="p-4 p-md-5 border rounded-3 bg-light">
                    <p class="text-center">Родители</p>
                    <div class="row">
                        <div class="col">
                          <input type="text" class="form-control" placeholder="First name">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Last name">
                        </div>
                    </div>
                    <hr class="my-4">
                    <button type="button" class="w-100 btn btn-lg btn-secondary btn-sm">Добавить</button>
                    <hr class="my-4">
                    <p class="text-center">Дети</p>
                    <div class="row">
                        <div class="col">
                          <input type="text" class="form-control" placeholder="First name">
                        </div>
                        <div class="col">
                          <input type="text" class="form-control" placeholder="Last name">
                        </div>
                    </div>
                    <hr class="my-4">
                    <button type="button" class="w-100 btn btn-lg btn-secondary btn-sm">Добавить</button>
                    <hr class="my-4">
                    <button class="w-100 btn btn-lg btn-primary" type="submit" control-id="ControlID-4">Забронировать</button>
                </form>
              </div>
            </div>
          </div>
      </div>
    </div>
</section>