<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center vh-100">

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="container col-xl-10 col-xxl-8 px-4 py-5">
            <div class="row align-items-center g-lg-5 py-5">
              <div class="col-lg-7 text-center text-lg-start">
                <h1 class="display-4 fw-bold lh-1 mb-3">Вход</h1>
                <!-- <p class="col-lg-10 fs-4">Below is an example form built entirely with Bootstrap’s form controls. Each required form group has a validation state that can be triggered by attempting to submit the form without completing it.</p> -->
              </div>
              <div class="col-md-10 mx-auto col-lg-5">
                <form class="p-4 p-md-5 border rounded-3 bg-light">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" control-id="ControlID-1">
                    <label for="floatingInput">Электронная почта</label>
                  </div>
                  <div class="form-floating mb-3">
                    <input type="password" class="form-control" id="floatingPassword" placeholder="Password" control-id="ControlID-2">
                    <label for="floatingPassword">Пароль</label>
                  </div>
                  <div class="checkbox mb-3">
                    <label>
                      <input type="checkbox" value="remember-me" control-id="ControlID-3"> Запомнить
                    </label>
                  </div>
                  <button class="w-100 btn btn-lg btn-primary" type="submit" control-id="ControlID-4">Войти</button>
                  <!-- <hr class="my-4">
                  <small class="text-muted">Нажимая «Войти», Вы соглашаетесь с условиями использования.</small> -->
                </form>
              </div>
            </div>
          </div>
      </div>
    </div>

  </section><!-- End Hero -->