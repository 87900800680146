  <!-- ======= Header ======= -->
    <!-- Image and text -->
    <nav class="navbar navbar-light bg-dark fixed-top p-4 b-09 m-3 shadow-lg" style="z-index: 9999999999999;">
      <a class="navbar-brand" routerLink="/">
        <img src="assets/img/logo-big.png" width="30" height="30" class="d-inline-block align-top" alt="">
      </a>
      <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
        <li><a routerLink="/" href="#hero" class="nav-link px-2 text-secondary">Главная</a></li>
        <li><a href="#about" class="nav-link px-2 text-white">О Нас</a></li>
        <li><a href="#portfolio" class="nav-link px-2 text-white">Фото</a></li>
        <li><a href="#faq" class="nav-link px-2 text-white">Вопросы</a></li>
        <li><a href="#contact" class="nav-link px-2 text-white">Контакты</a></li>
        <li><a routerLink="/reservation" class="nav-link px-2 text-white">Забронировать</a></li>
      </ul>
      <div class="text-end">
        <button type="button" class="btn btn-outline-light me-2" routerLink="/login">Вход</button>
        <button type="button" class="btn fc btn-primary" routerLink="/signup">Регистрация</button>
      </div>
    </nav>
<!-- End Header -->