<!-- ======= About Section ======= -->
<section id="about" class="about">
    <div class="container">

      <div class="row">
        <div class="col-lg-6 order-1 order-lg-2">
          <img src="assets/img/about_bg.png" class="img-fluid" alt="">
        </div>
        <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
          <h3>Что мы из себя представляем</h3>
          <p class="fst-normal fs-5">
            На производственных площадях завода СПКБ Техно появились мастерские (производство оборудования, никелирование, столярная мастерская, пекарня, сыроварня)
            На общем собрании решили, что имея такие возможности мы просто обязаны поделиться с подрастающим поколением и передать свой накопленный опыт, а возможно и открыть в ком то таланты.
          </p>
          <!-- <ul>
            <li><i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
            <li><i class="bi bi-check-circle"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
            <li><i class="bi bi-check-circle"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
          </ul> -->
          <!-- <a href="#" class="read-more">Read More <i class="bi bi-long-arrow-right"></i></a> -->
        </div>
      </div>

    </div>
  </section><!-- End About Section -->