import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frequently-asked-questions',
  templateUrl: './frequently-asked-questions.component.html',
  styleUrls: ['./frequently-asked-questions.component.css']
})
export class FrequentlyAskedQuestionsComponent implements OnInit {

  constructor() { }
  public questions: Questions[] = [ 
    {question: "Вопрос 1", answer: "Ответ 1"},
    {question: "Вопрос 2", answer: "Ответ 2"}, 
    {question: "Вопрос 3", answer: "Ответ 3"}, 
    {question: "Вопрос 4", answer: "Ответ 4"}, 
    {question: "Вопрос 5", answer: "Ответ 5"}, 
    {question: "Вопрос 6", answer: "Ответ 6"}, 
    {question: "Вопрос 7", answer: "Ответ 7"}, 
  ];
  expandedIndex = 0;
  ngOnInit(): void {
  }

}
interface Questions{
  question: string;
  answer: string;
}