  <!-- ======= Hero Section ======= -->
  <app-hero></app-hero>
  <!-- End Hero -->

  <main id="main">
    <!-- ======= Pricing Section ======= -->
    <app-pricing></app-pricing>
    <!-- End Pricing Section -->
    <!-- ======= About Section ======= -->
    <app-about></app-about>
    <!-- End About Section -->
    <!-- ======= Counts Section ======= -->
    <!-- <app-counts></app-counts> -->
    <!-- End Counts Section -->
    <!-- ======= Services Section ======= -->
    <app-services></app-services>
    <!-- End Services Section -->
    <!-- ======= Features Section ======= -->
    <!-- <app-features></app-features> -->
    <!-- End Features Section -->
    <!-- ======= Testimonials Section ======= -->
    <!-- <app-testimonials></app-testimonials> -->
    <!-- End Testimonials Section -->
    <!-- ======= Portfolio Section ======= -->
    <app-portfolio></app-portfolio>
    <!-- End Portfolio Section -->
    <!-- ======= Frequently Asked Questions Section ======= -->
    <app-frequently-asked-questions></app-frequently-asked-questions>
    <!-- End Frequently Asked Questions Section -->
    <!-- ======= Contact Section ======= -->
    <app-contact></app-contact>
    <!-- End Contact Section -->
  </main><!-- End #main -->

  <!-- <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  <div id="preloader"></div> -->

  <!-- Vendor JS Files -->
  <!-- <script src="assets/vendor/purecounter/purecounter.js"></script>
  <script src="assets/vendor/aos/aos.js"></script>
  <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
  <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
  <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
  <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
  <script src="assets/vendor/php-email-form/validate.js"></script> -->

  <!-- Template Main JS File -->
  <!-- <script src="assets/js/main.js"></script> -->
