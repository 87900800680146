<!-- ======= Contact Section ======= -->
<section id="contact" class="contact section-bg">
    <div class="container">

      <div class="section-title">
        <h2>Ждем Вас и Ваших детей!</h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="info-box mb-4 b-09">
            <i class="bx bx-map"></i>
            <h3>Адрес</h3>
            <p>Бронницкая ул., 5, Подольск, Московская обл., Россия, 142103</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4 b-09">
            <i class="bx bx-envelope"></i>
            <h3>Email</h3>
            <p>contact@example.com</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="info-box  mb-4 b-09">
            <i class="bx bx-phone-call"></i>
            <h3>Телефон</h3>
            <p>+7 495 505-68-50</p>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col-lg-6 b-09">
          <iframe class="mb-4 mb-lg-0 b-09" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9054.995268637142!2d37.5747761!3d55.4321741!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x184eaf913c2fab42!2z0JDQniAi0KHQn9Ca0JEg0KLQtdGF0L3QviI!5e0!3m2!1sru!2sua!4v1637969280339!5m2!1sru!2sua" frameborder="0" style="border:0; width: 100%; height: 384px;" allowfullscreen></iframe>
        </div>
        
        <div class="col-lg-6">
          <form action="forms/contact.php" method="post" role="form" class="php-email-form b-09">
            <h3>Остались вопросы?</h3>
            <div class="row">
              <div class="col-md-6 form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Имя" required>
              </div>
              <div class="col-md-6 form-group mt-3 mt-md-0">
                <input type="email" class="form-control" name="email" id="email" placeholder="Email" required>
              </div>
            </div>
            <div class="form-group mt-3">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Тема" required>
            </div>
            <div class="form-group mt-3">
              <textarea class="form-control" name="message" rows="5" placeholder="Сообщение" required></textarea>
            </div>
            <div class="my-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
              <div class="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div class="text-center"><button type="submit">Отправить</button></div>
          </form>
        </div>

      </div>

    </div>
  </section><!-- End Contact Section -->