<!-- ======= Frequently Asked Questions Section ======= -->
<section id="faq" class="faq">
    <div class="container">

      <div class="section-title">
        <h2>Часто задаваемые вопросы</h2>
        <p>Описание</p>
      </div>

      <div class="faq-list">
        <mat-card class="b-09">
          <cdk-accordion class="example-accordion">
            <cdk-accordion-item
              *ngFor="let question of questions; let index = index;"
              #accordionItem="cdkAccordionItem"
              class="example-accordion-item"
              role="button"
              tabindex="0"
              [attr.id]="'accordion-header-' + index"
              [attr.aria-expanded]="accordionItem.expanded"
              [attr.aria-controls]="'accordion-body-' + index">
              <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                {{ question.question }}
                <span class="example-accordion-item-description">
                  Нажмите что бы {{ accordionItem.expanded ? 'закрыть' : 'открыть' }}
                </span>
              </div>
              <div
                class="example-accordion-item-body"
                role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'"
                [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">
                {{ question.answer }}
              </div>
            </cdk-accordion-item>
          </cdk-accordion>
        </mat-card>
      </div>
    </div>
  </section><!-- End Frequently Asked Questions Section -->