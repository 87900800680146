<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">

    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 pt-3 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1>Предпринимательское сообщество spkbplace представляет.</h1>
          <h2>Мастер-классы для детей и взрослых!</h2>
          <div><a routerLink="/reservation" class="btn-get-started scrollto">Забронировать</a></div>
        </div>
        <div class="col-xl-4 col-lg-6 order-1 order-lg-2 hero-img">
          <img src="assets/img/noroot.png" class="img-fluid animated big-img" alt="">
        </div>
      </div>
    </div>

  </section><!-- End Hero -->